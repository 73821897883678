<template>
  <div class="nj-grid">
    <nj-question-dialog
      ref="removeQuestionDialog"
      title="Excluir Configuração de Fila de Trabalho"
      text="Deseja realmente excluir a configuração de fila de trabalho?"
    />
    <div class="header">
      <span class="title">Listagem de Configurações de filas de trabalho</span>
      <v-btn
        small
        color="primary"
        title="Nova Configuração de Fila de Trabalho"
        elevation="0"
        to="/medical_report_settings/new"
      >
        <v-icon left> mdi-plus </v-icon>
        Novo
      </v-btn>
    </div>
    <v-data-table
      :headers="headers"
      :items="items"
      class="nj-table"
      :loading="loading"
      loading-text="Buscando..."
      ref="dataTable"
      hide-default-footer
      disable-pagination
    >
      <template v-slot:item.updated_at="{ item }">
        {{ item.updated_at | njDateFormat(null, 'DD/MM/YYYY HH:mm') }}
      </template>
      <template v-slot:item.enable="{ item }">
        <v-icon color="green" title="Sim" v-if="item.enable">mdi-check</v-icon>
        <v-icon color="red" title="Não" v-else>mdi-cancel</v-icon>
      </template>
      <template v-slot:item.controls="{ item }">
        <div style="width: 160px">
          <v-btn
            :to="{ name: 'MedicalReportSettingShow', params: { id: item.id } }"
            title="Visualizar"
            icon
          >
            <v-icon color="green">mdi-eye</v-icon>
          </v-btn>
          <v-btn
            :to="{ name: 'MedicalReportSettingEdit', params: { id: item.id } }"
            title="Editar"
            icon
          >
            <v-icon color="orange">mdi-square-edit-outline</v-icon>
          </v-btn>
          <v-btn @click="remove(item)" title="Remover" icon>
            <v-icon color="red">mdi-delete</v-icon>
          </v-btn>
        </div>
      </template>
    </v-data-table>
  </div>
</template>
<script>
import Api from '@/resources/NajaSocialApi'

export default {
  name: 'MedicalReportSettings',
  data: () => ({
    searchTerm: '',
    loading: false,
    items: [],
    operations: [],
    options: {},
    headers: [
      { text: 'Fila de Trabalho', value: 'description' },
      { text: 'Ações', value: 'controls', sortable: false },
    ],
  }),

  mounted() {
    this.search()
  },

  methods: {
    remove(item) {
      if (!this.$hasPermission('naja_app_roles_destroy')) {
        alert('Acesso negado')
        return
      }

      this.$refs.removeQuestionDialog.open({
        onConfirm: () => {
          this.$refs.removeQuestionDialog.toggleLoading()
          Api.medicalReportSettings
            .destroy(item.id)
            .then(() => {
              this.search()
            })
            .catch(error => {
              if (error.response) {
                if (error.response.status == 500) {
                  this.$root.$children[0].toast(
                    'Erro ao remover configuração de filas de trabalho',
                    'error'
                  )
                } else {
                  this.$root.$children[0].toast(
                    error.response.data.error,
                    'error'
                  )
                }
              } else {
                throw error
              }
            })
            .finally(() => {
              this.$refs.removeQuestionDialog.toggleLoading()
              this.$refs.removeQuestionDialog.close()
            })
        },
      })
    },

    search() {
      this.loading = true

      Api.medicalReportSettings
        .search({})
        .then(response => {
          this.items = response.data.medical_report_settings
        })
        .catch(error => {
          throw error
        })
        .finally(() => {
          this.loading = false
          this.$scrollTop()
        })
    },
  },
}
</script>
